<template>
  <div class="animated fadeIn">
    <b-card :title="$t('message.salesByMonth')">
      <b-form @submit.prevent="searchFn">
        <div class="row">
          <div class="col-sm-6 col-md-6 col-lg-2 my-1">
            {{ $t("message.year") }} :
            <br />
            <b-form-select v-model="timeSel" :options="language== 'th'? timeOptTH: timeOptEN" v-on:change="selectTime" />
          </div>

          <div class="col-sm-6 col-md-6 col-lg-4 my-1">
            {{ $t("message.machineIdFarm") }} :
            <br />
            <multi-list-select
              :list="machineData"
              option-value="machineId"
              option-text="name"
              :selected-items="selectedMachine"
              :placeholder="$t('message.all')"
              @select="onSelectMachine"
            ></multi-list-select>
          </div>

          <div class="col-sm-6 col-md-6 col-lg-4 my-1">
            {{ $t("message.selectDateTime") }} :
            <br />
            <date-time-picker v-model="searchData.datetime"></date-time-picker>
          </div>

          <div class="col-sm-6 col-md-6 col-lg-2 my-3">
            <b-btn
              class="col-sm-12 col-md-12 ml-12 mr-3 my-1"
              variant="primary"
              type="submit"
            >
              <i class="fa fa-search"></i>
              &nbsp;{{ $t("message.search") }}
            </b-btn>
          </div>
        </div>
        <br />
      </b-form>

      <div class="row" v-if="series_overview[1].data != ''">
        <div id="chart" class="col-12 col-sm-12 col-md-12 col-lg-8" >
          <apexchart type="area" height="350" :options="chartOptions_overview" :series="series_overview"></apexchart>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-4">
          <div class="table-responsive col-12 col-sm-12 col-md-12 col-lg-12" style="padding-left: 10%; padding-right: 10%;">
            <table class="table-grid">
              <thead style="text-align-last: center">
                <th colspan="1"></th>

                <template v-for="row in caseProductType_Intersection">
                  <th v-if="language== 'th' && row == 'washer'">{{ $t("message.washer") }}</th>
                  <th v-if="language== 'th' && row == 'dryer'">{{ $t("message.dryer") }}</th>
                  <th v-if="language== 'th' && row == 'solution'">{{ $t("message.solution") }}</th>

                  <th v-if="language== 'gb' && row == 'washer'">{{ $t("message.washer") }}</th>
                  <th v-if="language== 'gb' && row == 'dryer'">{{ $t("message.dryer") }}</th>
                  <th v-if="language== 'gb' && row == 'solution'">{{ $t("message.solution") }}</th>
                </template>
                <th>{{ $t("message.tote") }}</th>
              </thead>
              <tbody>
                <template v-for="saleData in Overview">
                  <tr>
                    <td style="text-align: right; width: 25%;  ">
                      {{ saleData.month }}  {{ saleData.year }}
                    </td>
                    <template v-for="data in saleData.overview" >
                      <td style="text-align: right">
                        {{ Number(data.total).toLocaleString() }}
                      </td>
                    </template>
                    <td style="text-align: right; width: 10%; ">
                      {{ Number(saleData.total).toLocaleString() }}
                    </td>
                  </tr>
                </template>
                <tr style="text-align: right; ">
                  <td>{{ $t("message.tote") }}</td>
                  <td v-if="sumTotal_washer > 0">{{ Number(sumTotal_washer).toLocaleString() }}</td>
                  <td v-if="sumTotal_dryer > 0">{{ Number(sumTotal_dryer).toLocaleString() }}</td>
                  <td v-if="sumTotal_solution > 0">{{ Number(sumTotal_solution).toLocaleString() }}</td>
                  <td>{{ Number(sumTotal_washer + sumTotal_dryer + sumTotal_solution).toLocaleString() }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>

      <br />

      <div class="row my-4"  v-if="series_overview[0].data != ''">
        <!-- ================ washerName ================ -->
        <div :class="year_and_monthIntersection.length <= 4 ? 'col-12 col-sm-12 col-md-12 col-lg-6 my-4':  'col-12 col-sm-12 col-md-12 col-lg-12 my-4'" v-if="SaleByTypeWasher.length">
          <h4 style="text-align: center;">{{ $t("message.washer") }}</h4>
          <div id="chart">
            <apexchart type="line" height="350" :options="chartOptions_washer" :series="series_washer"></apexchart>
          </div>

          <div class="table-responsive" :style="year_and_monthIntersection.length <= 2 ?  'padding-left: 12%; padding-right: 12%;' : 'padding-left: 10%; padding-right: 10%;' ">
            <table class="table-grid">
              <thead style="text-align-last: center">
                <th colspan="1">{{ $t("message.No") }}</th>
                <th colspan="1">{{ $t("message.productName") }}</th>
                <template v-for="row in year_and_monthIntersection">
                  <th >{{ row }}</th>
                </template>
                <th>{{ $t("message.tote") }}</th>
              </thead>
               <tbody>
                <template v-for="(saleData, index) in SaleByTypeWasher">
                  <tr>
                    <td style="text-align: center; width: 3%;">
                      {{ index + 1}}
                    </td>
                    <td style="text-align: right; width: 35%; ">
                      {{ saleData.name }}
                    </td>
                    <template v-for="data in saleData.washer">
                      <td style="text-align: right">
                        {{ Number(data.total).toLocaleString() }}
                      </td>
                    </template>
                    <td style="text-align: right; width: 6%; ">
                      {{ Number(saleData.total).toLocaleString() }}
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>

        <!-- ================ dryerName ================ -->
        <div :class="year_and_monthIntersection.length <= 4 ? 'col-12 col-sm-12 col-md-12 col-lg-6 my-4':  'col-12 col-sm-12 col-md-12 col-lg-12 my-4'" v-if="SaleByTypeDryer.length">
          <h4 style="text-align: center;">{{ $t("message.dryer") }}</h4>
          <div id="chart">
            <apexchart type="line" height="350" :options="chartOptions_dryer" :series="series_dryer"></apexchart>
          </div>

          <div class="table-responsive" :style="year_and_monthIntersection.length <= 2 ?  'padding-left: 12%; padding-right: 12%;' : 'padding-left: 10%; padding-right: 10%;' ">
            <table class="table-grid">
               <thead style="text-align-last: center">
                <th colspan="1">{{ $t("message.No") }}</th>
                <th colspan="1">{{ $t("message.productName") }}</th>
                <template v-for="row in year_and_monthIntersection">
                  <th >{{ row }}</th>
                </template>
                <th>{{ $t("message.tote") }}</th>
              </thead>
             <tbody>
                <template v-for="(saleData, index) in SaleByTypeDryer">
                  <tr>
                    <td style="text-align: center; width: 3%;">
                      {{ index + 1}}
                    </td>
                    <td style="text-align: right; width: 35%; ">
                      {{ saleData.name }}
                    </td>
                    <template v-for="data in saleData.dryer">
                      <td style="text-align: right">
                        {{ Number(data.total).toLocaleString() }}
                      </td>
                    </template>
                    <td style="text-align: right; width: 6%; ">
                      {{ Number(saleData.total).toLocaleString() }}
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>

        <!-- ================ solutionName ================ -->
       <div :class="year_and_monthIntersection.length <= 4 ? 'col-12 col-sm-12 col-md-12 col-lg-12 my-4':  'col-12 col-sm-12 col-md-12 col-lg-12 my-4'" v-if="SaleByTypeSolution.length">
        <h4 style="text-align: center;">{{ $t("message.solution") }}</h4>

         <div id="chart">
            <apexchart type="line" height="350" :options="chartOptions_solution" :series="series_solution"></apexchart>
          </div>

          <div class="table-responsive" :style="year_and_monthIntersection.length <= 2 ?  'padding-left: 20%; padding-right: 20%;' : 'padding-left: 10%; padding-right: 10%;' ">
            <table class="table-grid">
              <thead style="text-align-last: center">
                <th colspan="1">{{ $t("message.No") }}</th>
                <th colspan="1">{{ $t("message.productName") }}</th>
                <template v-for="row in year_and_monthIntersection">
                  <th >{{ row }}</th>
                </template>
                <th>{{ $t("message.tote") }}</th>
              </thead>
              <tbody>
                <template v-for="(saleData, index ) in SaleByTypeSolution">
                  <tr>
                    <td style="text-align: center; width: 3%;">
                      {{ index + 1}}
                    </td>
                    <td style="text-align: right; width: 35%;">
                      {{ saleData.name }}
                    </td>
                    <template v-for="data in saleData.solution">
                      <td style="text-align: right">
                        {{ Number(data.total).toLocaleString() }}
                      </td>
                    </template>
                    <td style="text-align: right; width: 6%; ">
                      {{ Number(saleData.total).toLocaleString() }}
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
       </div>
      </div>


    </b-card>
  </div>
</template>

<script>
import webServices from "../../script";
import DateTimePicker from "../../components/DateTimePicker/MonthCustom";

import {
  MultiSelect,
  MultiListSelect,
} from "../../custom_modules/search-select";
import moment from "moment";
import VueJsonPretty from "vue-json-pretty";

export default {
  name: "orders",
  components: {
    DateTimePicker,
    MultiSelect,
    MultiListSelect,
    VueJsonPretty,
  },
  data() {
    return {
      api: {},
      timeSel: 0,
      timeOptTH: [
        { value: 0, text: '2022 ถึง ปีปัจจุบัน' },
        { value: 1, text: '<2022'}
      ],
      timeOptEN: [
        { value: 0, text: '2022 To This Year' },
        { value: 1, text: '<2022'}
      ],
      language: window.localStorage.getItem("language"),
      role_id: window.localStorage.getItem("roleID"),
      userBusiness: window.localStorage.getItem("business"),
      selectedProduct: [],
      selectedMachine: [],
      machineData: [],
      selectedId: {},
      searchData: {
        // datetime: "",
        datetime: [moment().startOf('month').toDate(), moment().endOf('month').toDate()],
      },
      series_overview: [
        {
          data: [],
        },
        {
          data: [],
        },
        {
          data: [],
        },
        {
          data: [],
        },
      ],

      chartOptions_overview: {
        colors: ["#00e396", "#3498DB", "#FEB019", "#9C27B0", "#D92139"],
        chart: {
          height: 350,
          type: 'area'
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          categories: [],
          type: "category",
          tickAmount: undefined,
          tickPlacement: "between",
          min: undefined,
          max: undefined,
          range: undefined,
          floating: false,
          decimalsInFloat: undefined,
          overwriteCategories: undefined,
          position: "bottom",
          labels: {
            show: true,
            // rotate: -45,
            rotateAlways: false,
            hideOverlappingLabels: true,
            showDuplicates: false,
            trim: false,
            minHeight: undefined,
            maxHeight: 120,
            style: {
              colors: [],
              fontSize: "12px",
              fontWeight: 400,
              cssClass: "apexcharts-xaxis-label",
            },
          },
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return  Number(value).toLocaleString()
            }
          },
        },
      },

      // series_washer
      series_washer: [],
      chartOptions_washer: {
            //  colors: ["#AEC33A", "#FEC748", "#3498DB", "#9C27B0", "#D92139"],
        chart: {
          type: "line",
          height: 350,
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: [],
          type: "category",
          tickAmount: undefined,
          tickPlacement: "between",
          min: undefined,
          max: undefined,
          range: undefined,
          floating: false,
          decimalsInFloat: undefined,
          overwriteCategories: undefined,
          position: "bottom",
          labels: {
            show: true,
            rotate: -45,
            rotateAlways: false,
            hideOverlappingLabels: true,
            showDuplicates: false,
            trim: false,
            minHeight: undefined,
            maxHeight: 120,
            style: {
              colors: [],
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 400,
              cssClass: "apexcharts-xaxis-label",
            },
            offsetX: 0,
            offsetY: 0,
          },
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return  Number(value).toLocaleString()
            }
          },
        },
        fill: {
          opacity: 1,
        },

      },

      // series_dryer
      series_dryer: [],
      chartOptions_dryer: {
        //  colors: ["#AEC33A", "#FEC748", "#3498DB", "#9C27B0", "#D92139"],
        chart: {
          type: "line",
          height: 350,
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: [],
          type: "category",
          tickAmount: undefined,
          tickPlacement: "between",
          min: undefined,
          max: undefined,
          range: undefined,
          floating: false,
          decimalsInFloat: undefined,
          overwriteCategories: undefined,
          position: "bottom",
          labels: {
            show: true,
            rotate: -45,
            rotateAlways: false,
            hideOverlappingLabels: true,
            showDuplicates: false,
            trim: false,
            minHeight: undefined,
            maxHeight: 120,
            style: {
              colors: [],
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 400,
              cssClass: "apexcharts-xaxis-label",
            },
            offsetX: 0,
            offsetY: 0,
          },
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return  Number(value).toLocaleString()
            }
          },
          // title: {
          //   text: "$ (thousands)",
          // },
        },
        fill: {
          opacity: 1,
        },

      },

      // series_solution
      series_solution: [],
      chartOptions_solution: {
        colors: ["#3498DB", "#00e396", "#FEB019", "#ff4560", "#9C27B0", "#19B3B1", "#AEC33A", "#FEC748", "#FFA289", "#706FA8"],
        chart: {
          type: "line",
          height: 350,
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: [],
          type: "category",
          tickAmount: undefined,
          tickPlacement: "between",
          min: undefined,
          max: undefined,
          range: undefined,
          floating: false,
          decimalsInFloat: undefined,
          overwriteCategories: undefined,
          position: "bottom",
          labels: {
            show: true,
            rotate: -45,
            rotateAlways: false,
            hideOverlappingLabels: true,
            showDuplicates: false,
            trim: false,
            minHeight: undefined,
            maxHeight: 120,
            style: {
              colors: [],
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 400,
              cssClass: "apexcharts-xaxis-label",
            },
            offsetX: 0,
            offsetY: 0,
          },
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return  Number(value).toLocaleString()
            }
          },
        },
        fill: {
          opacity: 1,
        },
      },

      SaleByType: [],
      SaleByType_GYM: {},

      washer: {
        productId: [],
        name: [],
        total: [],
        year_and_month: [],
      },
      dryer: {
        productId: [],
        name: [],
        total: [],
        year_and_month: [],
      },
      solution: {
        productId: [],
        name: [],
        total: [],
        year_and_month: [],
      },


      year_and_monthAll: [],
      year_and_monthIntersection: {},

      caseProductType_Intersection: [],

      year_and_month_Washer: [],
      year_and_month_Dryer: [],
      year_and_month_Softener: [],

      washerName: [],
      dryerName: {},
      solutionName: [],


      sumTotal_washer: 0,
      sumTotal_dryer: 0,
      sumTotal_solution: 0,
    };
  },

  computed: {

    Overview(){
      // const saleOverviewList = this.SaleByType.filter((data) => {
      //   return data.caseProductType === "overview";
      // });

      const saleOverviewList = this.SaleByType_GYM
      const year_and_month_list = [...new Set(saleOverviewList.map(data => data.year_and_month))]

      const saleByTypeWasher = [];

      for (let i = 0; i < year_and_month_list.length; i++) {
        let saleOverview = {
          productId: '',
          name: '',
          total: 0,
          overview: [],
          year_and_month: '',
          year: '',
          month: '',
        };
        saleOverview.year_and_month = year_and_month_list[i];
        saleOverview.year = year_and_month_list[i].toString().substring(0, 4);

        if(window.localStorage.getItem("language") == 'th'){
          if(year_and_month_list[i].toString().substring(4, 6) == '01'){  saleOverview.month = 'ม.ค' }
          if(year_and_month_list[i].toString().substring(4, 6) == '02'){  saleOverview.month = 'ก.พ.' }
          if(year_and_month_list[i].toString().substring(4, 6) == '03'){  saleOverview.month = 'มี.ค.' }
          if(year_and_month_list[i].toString().substring(4, 6) == '04'){  saleOverview.month = 'เม.ย.' }
          if(year_and_month_list[i].toString().substring(4, 6) == '05'){  saleOverview.month = 'พ.ค.' }
          if(year_and_month_list[i].toString().substring(4, 6) == '06'){  saleOverview.month = 'มิ.ย.' }
          if(year_and_month_list[i].toString().substring(4, 6) == '07'){  saleOverview.month = 'ก.ค.' }
          if(year_and_month_list[i].toString().substring(4, 6) == '08'){  saleOverview.month = 'ส.ค.' }
          if(year_and_month_list[i].toString().substring(4, 6) == '09'){  saleOverview.month = 'ก.ย.' }
          if(year_and_month_list[i].toString().substring(4, 6) == '10'){  saleOverview.month = 'ต.ค.' }
          if(year_and_month_list[i].toString().substring(4, 6) == '11'){  saleOverview.month = 'พ.ย.' }
          if(year_and_month_list[i].toString().substring(4, 6) == '12'){  saleOverview.month = 'ธ.ค.' }
        }else{
          if(year_and_month_list[i].toString().substring(4, 6) == '01'){  saleOverview.month = 'Jan.' }
          if(year_and_month_list[i].toString().substring(4, 6) == '02'){  saleOverview.month = 'Feb.' }
          if(year_and_month_list[i].toString().substring(4, 6) == '03'){  saleOverview.month = 'Mar.' }
          if(year_and_month_list[i].toString().substring(4, 6) == '04'){  saleOverview.month = 'Apr.' }
          if(year_and_month_list[i].toString().substring(4, 6) == '05'){  saleOverview.month = 'May.' }
          if(year_and_month_list[i].toString().substring(4, 6) == '06'){  saleOverview.month = 'Jun.' }
          if(year_and_month_list[i].toString().substring(4, 6) == '07'){  saleOverview.month = 'Jul.' }
          if(year_and_month_list[i].toString().substring(4, 6) == '08'){  saleOverview.month = 'Aug.' }
          if(year_and_month_list[i].toString().substring(4, 6) == '09'){  saleOverview.month = 'Sept.' }
          if(year_and_month_list[i].toString().substring(4, 6) == '10'){  saleOverview.month = 'Oct.' }
          if(year_and_month_list[i].toString().substring(4, 6) == '11'){  saleOverview.month = 'Nov.' }
          if(year_and_month_list[i].toString().substring(4, 6) == '12'){  saleOverview.month = 'Dec.' }
        }

        // const saleOverviewListFilterByOverview = saleOverviewList.filter(data => data.caseProductType === this.caseProductType_Intersection[i])
        saleOverview.name = this.caseProductType_Intersection[i];

        const saleOverviewListFilterByOverview = saleOverviewList.filter(data => data.year_and_month === year_and_month_list[i])
        // console.log('test', saleOverviewListFilterByOverview)

        let index = 0
        for (let j = 0; j < this.caseProductType_Intersection.length; j++) {
          const saleOverviewTemp = saleOverviewListFilterByOverview[index] ? saleOverviewListFilterByOverview[index] : {}

          // console.log('saleOverviewTemp', saleOverviewTemp)
          if(saleOverviewTemp.caseProductType === this.caseProductType_Intersection[j]) {
            saleOverview.overview.push({
              type: this.caseProductType_Intersection[j],
              trans: saleOverviewTemp.trans,
              total: saleOverviewTemp.total,
            });
            saleOverview.total =  saleOverview.total + ( saleOverviewTemp.total  ? saleOverviewTemp.total  : 0  )
            index++

          } else {
            saleOverview.overview.push({
              type: this.caseProductType_Intersection[j],
              trans:0,
              total: 0
            });
          }
          saleOverview.productId = saleOverviewList[j].product.productId;
        }
        saleByTypeWasher.push(saleOverview);

        this.series_overview[0].data.push(saleOverview.total);
      }

      return saleByTypeWasher;
    },

    SaleByTypeWasher() {
      const saleWasherList = this.SaleByType.filter((data) => {
        return data.caseProductType === "washer";
      });

      const year_and_month_list = [...new Set(saleWasherList.map(data => data.year_and_month))]
      const saleByTypeWasher = [];
      for (let i = 0; i < this.washerName.length; i++) {
        let saleWasher = {
          productId: '',
          name: '',
          total: 0,
          washer: [],
        };

        saleWasher.name = this.washerName[i];
        const saleWasherListFilterByWasher = saleWasherList.filter(data => data.product.name === this.washerName[i])
        // console.log('test', saleWasherListFilterByWasher)

        let index = 0
        for (let j = 0; j < year_and_month_list.length; j++) {
          const saleWasherTemp = saleWasherListFilterByWasher[index] ? saleWasherListFilterByWasher[index] : {}

          // console.log('saleWasherTemp', saleWasherTemp)
          if(saleWasherTemp.year_and_month === year_and_month_list[j]) {
            saleWasher.washer.push({
              year_and_month: year_and_month_list[j],
              trans: saleWasherTemp.trans,
              total: saleWasherTemp.total,
            });
            saleWasher.total =  saleWasher.total + ( saleWasherTemp.total  ? saleWasherTemp.total  : 0  )
            index++

          } else {
            saleWasher.washer.push({
              year_and_month: year_and_month_list[j],
              trans:0,
              total: 0
            });
          }
          saleWasher.productId = saleWasherList[j].product.productId;
        }
        saleByTypeWasher.push(saleWasher);
      }

      saleByTypeWasher.sort(function (a, b) {
        if (a.total < b.total) {
          return 1;
        } else if (a.total > b.total) {
           return -1;
        } else {
          return 0;
        }
      });

      this.series_washer = [];
      const list_name = [...new Set(saleByTypeWasher.map(data => data.name))]
      let count_list_name = list_name.length
      let index = 0

      // create array
      for (let o = 0; o < count_list_name ; o++) {
        if( o < 3){
          this.series_washer.push({name: '', data: [], type:'',});
        }
      }

      // insert data
      for (let x = 0; x < saleByTypeWasher.length; x++) {
        if(index < 3){
          for (let y = 0; y < year_and_month_list.length; y++) {
            if( saleByTypeWasher[x].name === list_name[index] ){
              this.series_washer[index].type = "column"
              this.series_washer[index].name =  list_name[index]
              this.series_washer[index].data.push(saleByTypeWasher[x].washer[y].total);
            }
          }
        }
        index ++
      }
      return saleByTypeWasher;
    },

    SaleByTypeDryer() {
      const saleDryerList = this.SaleByType.filter((data) => {
        return data.caseProductType === "dryer";
      });

      const year_and_month_list = [...new Set(saleDryerList.map(data => data.year_and_month))]
      const SaleByTypeDryer = [];
      for (let i = 0; i < this.dryerName.length; i++) {
        let saleDryer = {
          productId: '',
          name: '',
          total: 0,
          dryer: [],
        };

        saleDryer.name = this.dryerName[i];
        const saleDryerListFilterByDryer = saleDryerList.filter(data => data.product.name === this.dryerName[i])
        // console.log('test', saleDryerListFilterByDryer)

        let index = 0
        for (let j = 0; j < year_and_month_list.length; j++) {
          const saleDryerTemp = saleDryerListFilterByDryer[index] ? saleDryerListFilterByDryer[index] : {}

          // console.log('saleDryerTemp', saleDryerTemp)
          if(saleDryerTemp.year_and_month === year_and_month_list[j]) {
            saleDryer.dryer.push({
              year_and_month: year_and_month_list[j],
              trans: saleDryerTemp.trans,
              total: saleDryerTemp.total,
            });
            saleDryer.total =  saleDryer.total + ( saleDryerTemp.total  ? saleDryerTemp.total  : 0  )
            index++

          } else {
            saleDryer.dryer.push({
              year_and_month: year_and_month_list[j],
              trans:0,
              total: 0
            });
          }
          saleDryer.productId = saleDryerList[j].product.productId;
        }
        SaleByTypeDryer.push(saleDryer);
      }

      SaleByTypeDryer.sort(function (a, b) {
        if (a.total < b.total) {
          return 1;
        } else if (a.total > b.total) {
           return -1;
        } else {
          return 0;
        }
      });

      this.series_dryer = [];
      const list_name = [...new Set(SaleByTypeDryer.map(data => data.name))]
      let count_list_name = list_name.length
      let index = 0

      // create array
      for (let o = 0; o < count_list_name ; o++) {
        if( o < 3){
          this.series_dryer.push({name: '', data: [], type:'',});
        }
      }

      // insert data
      for (let x = 0; x < SaleByTypeDryer.length; x++) {
        if(index < 3){
          for (let y = 0; y < year_and_month_list.length; y++) {
            if( SaleByTypeDryer[x].name === list_name[index] ){
              this.series_dryer[index].type = "column"
              this.series_dryer[index].name =  list_name[index]
              this.series_dryer[index].data.push(SaleByTypeDryer[x].dryer[y].total);
            }
          }
        }
        index ++
      }

      return SaleByTypeDryer;
    },

    SaleByTypeSolution() {
      const saleSolutionList = this.SaleByType.filter((data) => {
        return data.caseProductType === "solution";
      });

      const year_and_month_list = [...new Set(saleSolutionList.map(data => data.year_and_month))]
      const SaleByTypeSolution = [];

      for (let i = 0; i < this.solutionName.length; i++) {
        let saleSolution = {
          productId: '',
          name: '',
          total: 0,
          solution: [],
        };

        saleSolution.name = this.solutionName[i];

        const saleSolutionListFilterBySolution = saleSolutionList.filter(data => data.product.name === this.solutionName[i])
        // console.log('test', saleSolutionListFilterBySolution)

        let index = 0
        for (let j = 0; j < year_and_month_list.length; j++) {
          const saleSolutionTemp = saleSolutionListFilterBySolution[index] ? saleSolutionListFilterBySolution[index] : {}

          // console.log('saleSolutionTemp', saleSolutionTemp)
          if(saleSolutionTemp.year_and_month === year_and_month_list[j]) {
            saleSolution.solution.push({
              year_and_month: year_and_month_list[j],
              trans: saleSolutionTemp.trans,
              total: saleSolutionTemp.total,
            });
            saleSolution.total =  saleSolution.total + ( saleSolutionTemp.total  ? saleSolutionTemp.total  : 0  )
            index++

          } else {
            saleSolution.solution.push({
              year_and_month: year_and_month_list[j],
              trans:0,
              total: 0
            });
          }
          saleSolution.productId = saleSolutionList[j].product.productId;
        }
        SaleByTypeSolution.push(saleSolution);
      }

      SaleByTypeSolution.sort(function (a, b) {
        if (a.total < b.total) {
          return 1;
        } else if (a.total > b.total) {
          return -1;
        } else {
          return 0;
        }
      });

      this.series_solution = [];
      const list_name = [...new Set(SaleByTypeSolution.map(data => data.name))]
      let count_list_name = list_name.length

      // create array
      for (let o = 0; o < count_list_name ; o++) {
        if( o < 10){
          this.series_solution.push({name: '', data: [], type:'',});
        }
      }

      // insert data
      let index = 0
      for (let x = 0; x < SaleByTypeSolution.length; x++) {
        if(index < 10){
          for (let y = 0; y < year_and_month_list.length; y++) {
            if( SaleByTypeSolution[x].name === list_name[index] ){
              this.series_solution[index].type = "column"
              this.series_solution[index].name =  list_name[index]
              this.series_solution[index].data.push(SaleByTypeSolution[x].solution[y].total);
            }
          }
        }
        index ++
      }
      return SaleByTypeSolution;
    },

  },

  async mounted() {
    await this.setParams(this.$route.query);
    await this.getMachine();

    if (!this.$isRole("admin", this.role_id) && !this.$isRole("callcenter", this.role_id)) {
      await this.getTransactions();
    }
  },
  methods: {
    selectTime(value) {
      if (value > 0) {
        this.api.defaults.baseURL = '/old/' + this.axios.defaults.baseURL
      } else {
        this.api.defaults.baseURL = this.axios.defaults.baseURL
      }
    },

    setParams(query) {
      if (Object.keys(query).length != 0) {
        // console.log(query)
        this.fromdate = query.from;
        this.todate = query.to;
        this.searchData.datetime = [query.from, query.to];
        this.searchData.productId = query.productId || "";
        this.searchData.machineId = query.machineId || "";
      }
    },

    async getMachine() {
      this.$Progress.start();
      await this.axios
        .get(`/machines/list`)
        .then((res) => {
          this.$Progress.finish();
          this.machineData = res.data;
          if (this.machineData.length == 1) {
            this.selectedMachine = this.machineData;
          }
        })
        .catch((err) => {
          this.$Progress.fail();
          this.$toast.error({
            title: "ERROR",
            message: {
              error: err.response.data,
              code: err.response.status,
              text: err.response.statusText,
            },
          });
          console.log("error @machine");
          console.log(err);
          if (err.response.status === 401) {
            webServices.tokenExpire();
          }
        });
    },

    showAlert_Warning() {
      if(window.localStorage.getItem("language") == 'th'){
        var title = "โปรดเลือกช่วงเวลาใหม่อีกครั้ง"
        var text = "ไม่สามารถเลือกเดือนได้มากกว่า 6 เดือน"
        var confirmButton = "ปิด"
      }else{
        var title = "Please select a new time again"
        var text = "Cannot select more than 6 months."
        var confirmButton = "Close"
      }

      this.$swal({
        title: title,
        text: text,
        icon: 'warning',
        // showCancelButton: true,
        confirmButtonColor: '#3085d6',
        // cancelButtonColor: '#d33',
        confirmButtonText: confirmButton
      })
    },

    monthDiff(d1, d2) {
      var months;
      months = (d2.getFullYear() - d1.getFullYear()) * 12;
      months -= d1.getMonth();
      months += d2.getMonth();
      return months <= 0 ? 0 : months;
    },

    async searchFn() {
      this.setupValue();
      console.log("selectedMachine", this.selectedMachine);
      console.log("searchData.datetime", this.searchData.datetime);

      var dateStart = new Date(this.searchData.datetime[0])
      var dateEnd = new Date(this.searchData.datetime[1])

      var localStorageUser =  JSON.parse(window.localStorage.getItem("users") )
      var datetimeRequest = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')

      this.searchData.userName = localStorageUser.userName
      this.searchData.userRole = localStorageUser.role
      this.searchData.userType = localStorageUser.type
      this.searchData.userConfiglanguage = localStorageUser.config.language
      this.searchData.datetimeRequest = datetimeRequest

      // console.log('monthDiff:', this.monthDiff(dateStart, dateEnd))

      if(this.monthDiff(dateStart, dateEnd) < 6){
        // console.log('เลือกสำเร็จ')
        await this.getSaleByType();
        await this.getSalesByType_GropYearMonth();
      }else{
        // console.log('กรุณาเลือใหม่')
        this.showAlert_Warning()
      }
    },

    async getSaleByType() {
      const params = {
        ...this.searchData,
        machine: this.selectedMachine,
        from: (this.searchData.from = moment(this.searchData.datetime[0])
          .startOf("month")
          .format("YYYY-MM-DD HH:mm:ss")),
        to: (this.searchData.to = moment(this.searchData.datetime[1])
          .endOf("month")
          .format("YYYY-MM-DD HH:mm:ss")),
        // type: "washing",
        // notThinkStatus: [
        //   "CANCEL_BY_MC_FAIL",
        //   "CANCEL_PAYMENT",
        //   "CANCEL_TIMEOUT",
        // ],
      };
      await this.axios
        .get(`/analyze/salesbymonth`, {
          params,
        })
        .then((res) => {
          this.SaleByType = res.data.data;

          if (res.data) {
            // console.log('xxxx', this.SaleByType)
            for (var i = 0; i < this.SaleByType.length; i++) {
              // console.log('year_and_month',this.SaleByType[i].year_and_month)

              if (this.SaleByType[i].caseProductType == "washer") {
                this.washer.productId.push(this.SaleByType[i].product.productId);
                this.washer.name.push(this.SaleByType[i].product.name);
                this.washer.total.push(this.SaleByType[i].total);
                this.washer.year_and_month.push(this.SaleByType[i].year_and_month);

              }
              if (this.SaleByType[i].caseProductType == "dryer") {
                this.dryer.productId.push(this.SaleByType[i].product.productId);
                this.dryer.name.push(this.SaleByType[i].product.name);
                this.dryer.total.push(this.SaleByType[i].total);
                this.dryer.year_and_month.push(this.SaleByType[i].year_and_month);

              }

              if (this.SaleByType[i].caseProductType == "solution") {
                this.solution.productId.push(this.SaleByType[i].product.productId);
                this.solution.name.push(this.SaleByType[i].product.name);
                this.solution.total.push(this.SaleByType[i].total);
                this.solution.year_and_month.push(this.SaleByType[i].year_and_month);
              }
            }

            // ในแต่ละประเภทไม่เอาชื่อซ้ำ
            this.washerName = [...new Set(this.washer.name)];
            this.dryerName = [...new Set(this.dryer.name)];
            this.solutionName = [...new Set(this.solution.name)];

            if(window.localStorage.getItem("language") == 'th'){
              this.series_overview[0].name = 'รวม'
              this.series_overview[1].name = 'ซักผ้า'
              this.series_overview[2].name = 'อกผ้า'
              this.series_overview[3].name = 'น้ำยา'

            }else{
              this.series_overview[0].name = 'sum'
              this.series_overview[1].name = 'washer'
              this.series_overview[2].name = 'dryer'
              this.series_overview[3].name = 'solution'

            }
          }
        })
        .catch((err) => {
          this.$Progress.fail();
          console.log(err);
          this.$toast.error({
            title: "ERROR",
            message: err,
          });
          if (err.response.status === 401) {
            webServices.tokenExpire();
          }
        });
    },

    async getSalesByType_GropYearMonth() {
      const params = {
        ...this.searchData,
        machine: this.selectedMachine,
        from: (this.searchData.from = moment(this.searchData.datetime[0])
          .startOf("month")
          .format("YYYY-MM-DD HH:mm:ss")),
        to: (this.searchData.to = moment(this.searchData.datetime[1])
          .endOf("month")
          .format("YYYY-MM-DD HH:mm:ss")),
        // type: "washing",
        // notThinkStatus: [
        //   "CANCEL_BY_MC_FAIL",
        //   "CANCEL_PAYMENT",
        //   "CANCEL_TIMEOUT",
        // ],
      };
      await this.axios
        .get(`/analyze/salesbymonth/groupyear_month`, {
          params,
        })
        .then((res) => {
          this.SaleByType_GYM = res.data.data;
          // console.log('this.SaleByType_GYM json', JSON.stringify(this.SaleByType_GYM) )

          if (res.data.data) {
            let indexMonthYear = 0;
            let year_and_month_Washer = [];
            let year_and_month_Dryer = [];
            let year_and_month_Softener = [];
            let caseProductTypeAll = [];

            let totalWasher = 0;
            let totalDryer = 0;
            let totalSolution = 0;

            let MonnthName = ''

            for (var i = 0; i < this.SaleByType_GYM.length; i++) {
              // this.year_and_monthAll.push(this.SaleByType_GYM[i].year_and_month);

              if(window.localStorage.getItem("language") == 'th'){
                if(this.SaleByType_GYM[i].year_and_month.toString().substring(4, 6) == '01'){ MonnthName = 'ม.ค' }
                if(this.SaleByType_GYM[i].year_and_month.toString().substring(4, 6) == '02'){ MonnthName = 'ก.พ.' }
                if(this.SaleByType_GYM[i].year_and_month.toString().substring(4, 6) == '03'){ MonnthName = 'มี.ค.' }
                if(this.SaleByType_GYM[i].year_and_month.toString().substring(4, 6) == '04'){ MonnthName = 'เม.ย.' }
                if(this.SaleByType_GYM[i].year_and_month.toString().substring(4, 6) == '05'){ MonnthName = 'พ.ค.' }
                if(this.SaleByType_GYM[i].year_and_month.toString().substring(4, 6) == '06'){ MonnthName = 'มิ.ย.' }
                if(this.SaleByType_GYM[i].year_and_month.toString().substring(4, 6) == '07'){ MonnthName = 'ก.ค.' }
                if(this.SaleByType_GYM[i].year_and_month.toString().substring(4, 6) == '08'){ MonnthName = 'ส.ค.' }
                if(this.SaleByType_GYM[i].year_and_month.toString().substring(4, 6) == '09'){ MonnthName = 'ก.ย.' }
                if(this.SaleByType_GYM[i].year_and_month.toString().substring(4, 6) == '10'){ MonnthName = 'ต.ค.' }
                if(this.SaleByType_GYM[i].year_and_month.toString().substring(4, 6) == '11'){ MonnthName = 'พ.ย.' }
                if(this.SaleByType_GYM[i].year_and_month.toString().substring(4, 6) == '12'){ MonnthName = 'ธ.ค.' }
              }else{
                if(this.SaleByType_GYM[i].year_and_month.toString().substring(4, 6) == '01'){ MonnthName = 'Jan.' }
                if(this.SaleByType_GYM[i].year_and_month.toString().substring(4, 6) == '02'){ MonnthName = 'Feb.' }
                if(this.SaleByType_GYM[i].year_and_month.toString().substring(4, 6) == '03'){ MonnthName = 'Mar.' }
                if(this.SaleByType_GYM[i].year_and_month.toString().substring(4, 6) == '04'){ MonnthName = 'Apr.' }
                if(this.SaleByType_GYM[i].year_and_month.toString().substring(4, 6) == '05'){ MonnthName = 'May  .' }
                if(this.SaleByType_GYM[i].year_and_month.toString().substring(4, 6) == '06'){ MonnthName = 'Jun.' }
                if(this.SaleByType_GYM[i].year_and_month.toString().substring(4, 6) == '07'){ MonnthName = 'Jul.' }
                if(this.SaleByType_GYM[i].year_and_month.toString().substring(4, 6) == '08'){ MonnthName = 'Aug.' }
                if(this.SaleByType_GYM[i].year_and_month.toString().substring(4, 6) == '09'){ MonnthName = 'Sept.' }
                if(this.SaleByType_GYM[i].year_and_month.toString().substring(4, 6) == '10'){ MonnthName = 'Oct.' }
                if(this.SaleByType_GYM[i].year_and_month.toString().substring(4, 6) == '11'){ MonnthName = 'Nov.' }
                if(this.SaleByType_GYM[i].year_and_month.toString().substring(4, 6) == '12'){ MonnthName = 'Dec.' }
              }

              this.year_and_monthAll.push(MonnthName+' '+ this.SaleByType_GYM[i].year_and_month.toString().substring(0, 4));
              caseProductTypeAll.push(this.SaleByType_GYM[i].caseProductType);


              if (this.SaleByType_GYM[i].caseProductType == "washer") {
                this.series_overview[1].data.push(this.SaleByType_GYM[i].total);
                year_and_month_Washer.push(this.SaleByType_GYM[i].year_and_month);
                totalWasher = totalWasher + this.SaleByType_GYM[i].total
              }

              if (this.SaleByType_GYM[i].caseProductType == "dryer") {
                this.series_overview[2].data.push(this.SaleByType_GYM[i].total);
                year_and_month_Dryer.push(this.SaleByType_GYM[i].year_and_month);
                totalDryer = totalDryer + this.SaleByType_GYM[i].total
              }

              if (this.SaleByType_GYM[i].caseProductType == "solution") {
                this.series_overview[3].data.push(this.SaleByType_GYM[i].total);
                year_and_month_Softener.push(this.SaleByType_GYM[i].year_and_month);
                totalSolution = totalSolution + this.SaleByType_GYM[i].total
              }



              if (i === this.SaleByType_GYM.length - 1 || this.SaleByType_GYM[i].year_and_month !== this.SaleByType_GYM[i + 1].year_and_month) {
                indexMonthYear++;
                if (this.series_overview[1].data.length !== indexMonthYear) {
                  this.series_overview[1].data.push(0);
                }
                if (this.series_overview[2].data.length !== indexMonthYear) {
                  this.series_overview[2].data.push(0);
                }
                if (this.series_overview[3].data.length !== indexMonthYear) {
                  this.series_overview[3].data.push(0);
                }
                // console.log("test after",JSON.parse(JSON.stringify(this.series_overview)));
              }
            }
            // console.log("year_and_monthAll", this.year_and_monthAll);
            this.year_and_monthIntersection = [...new Set(this.year_and_monthAll)];
            
            this.year_and_month_Washer = [...new Set(year_and_month_Washer)];
            this.year_and_month_Dryer = [...new Set(year_and_month_Dryer)];
            this.year_and_month_Softener = [...new Set(year_and_month_Softener)];

            this.caseProductType_Intersection = [...new Set(caseProductTypeAll)]

            this.sumTotal_washer = totalWasher
            this.sumTotal_dryer = totalDryer
            this.sumTotal_solution = totalSolution

            this.chartOptions_overview.xaxis.categories = this.year_and_monthIntersection;
            this.chartOptions_washer.xaxis.categories = this.year_and_monthIntersection;
            this.chartOptions_dryer.xaxis.categories = this.year_and_monthIntersection;
            this.chartOptions_solution.xaxis.categories = this.year_and_monthIntersection;
          }
        })
        .catch((err) => {
          this.$Progress.fail();
          console.log(err);
          this.$toast.error({
            title: "ERROR",
            message: err,
          });
          if (err.response.status === 401) {
            webServices.tokenExpire();
          }
        });
    },

    onSelectMachine(items) {
      // console.log(items)
      this.selectedMachine = items;
      this.searchData.machineId = this.selectedMachine.map(
        (data) => data.machineId
      );
    },

    onSelect(items) {
      // console.log(items)
      this.selectedProduct = items;
      this.searchData.productId = this.selectedProduct.map((data) => data.id);
    },

    changeTab(params) {
      this.rowData = {};
      this.totalRows = 0;
      this.totalSubRows = 0;
      this.summary = {};
      this.searchData.type = params;
      if (
        !this.$isRole("admin", this.role_id) &&
        !this.$isRole("callcenter", this.role_id)
      ) {
        this.searchFn();
      }
    },

    setupValue(){
      this.washer.name= [];
      this.dryer.name = [];
      this.solution.name = [];

      this.year_and_monthAll = [];
      this.year_and_monthIntersection = [];
      // this.year_and_month_Softener = [],

      this.washer.productId = [];
      this.washer.total = [];
      this.washer.name = [];

      this.series_overview[0].data = [];
      this.series_overview[1].data = [];
      this.series_overview[2].data = [];
      this.series_overview[3].data = [];

      this.chartOptions_overview.xaxis.categories = [];
      this.chartOptions_washer.xaxis.categories = [];
      this.chartOptions_dryer.xaxis.categories = [];
      this.chartOptions_solution.xaxis.categories = [];
    }
  },
};
</script>

